import React, { Fragment } from "react"
import styled from "styled-components"
import { StyledImage, StyledSectionContent } from "../styles/global"
import { deviceMax } from "../styles/mediaqueries"
import { toHTML } from "./../helpers/index"
import { colors } from "./../styles/colors"
import { MdPhone, MdEmail } from "react-icons/md"
import { fontweight } from "./../styles/variables"

const StyledSplitRow = styled.div`
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
    @media ${deviceMax.tablet} {
        flex-direction: column;
    }
    @media ${deviceMax.mobileL}, ${deviceMax.mobileM}, ${deviceMax.mobileS} {
        align-items: center;
        justify-content: center;
        padding: 0rem;
        padding-bottom: 4rem;
    }
`

const StyledContactCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background: white;
    left: 5rem;
    z-index: 2;
    padding: 2rem;
    position: relative;
    margin: 4rem 0;
    h3 {
        padding: 0.3rem 0rem 2rem 0;
        font-size: 21px;
        font-weight: ${fontweight.bold};
        color: ${colors.darkBlue};
    }

    p {
        padding: 0.8rem 0;
        font-size: 15px;
        color: ${colors.darkBlue};
        font-weight: ${fontweight.semibold};
        line-height: 27px;
        > a {
            padding: 0rem 0;
        }
    }
    @media ${deviceMax.laptop},
        ${deviceMax.tablet},
        ${deviceMax.mobileL},
        ${deviceMax.mobileM},
        ${deviceMax.mobileS} {
        height: auto;
        left: 0px;
    }
`

const StyledContactWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(355px, 1fr));
    align-items: center;
    width: 100%;
    @media ${deviceMax.mobileL}, ${deviceMax.mobileM}, ${deviceMax.mobileS} {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, minmax(80vw, 1fr));
    }
`

const StyledIconContactWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 1rem;
    color: ${colors.darkBlue};
    transition: all 0.3s ease-in-out;
    > .contact-details {
        padding: 0 5px;
        > p > a {
            color: ${colors.darkBlue};
            padding: 0;
            font-weight: ${fontweight.semibold};
        }
    }
`

const StyledFlexRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media ${deviceMax.mobileL}, ${deviceMax.mobileM}, ${deviceMax.mobileS} {
        flex-direction: column;
    }
`
interface IProps {
    text: {
        contact: string | undefined
        contactEmail: string | undefined
        contactTel: string | undefined
    }
    image: any
    slimImage?: boolean
}
const ArticleContact: React.FC<IProps> = ({ text, image, slimImage }) => {
    let { contact, contactEmail, contactTel } = text
    contact = toHTML(contact)
    contactEmail = toHTML(contactEmail)
    contactTel = toHTML(contactTel)
    return (
        <Fragment>
            <StyledSplitRow>
                <StyledContactWrapper>
                    <StyledSectionContent>
                        <StyledContactCard>
                            <div
                                className="main-content"
                                dangerouslySetInnerHTML={{
                                    __html: contact,
                                }}
                            ></div>
                            {text.contactEmail !== null && (
                                <StyledFlexRowWrapper>
                                    <StyledIconContactWrapper>
                                        <MdPhone />
                                        <div
                                            className="contact-details"
                                            dangerouslySetInnerHTML={{
                                                __html: contactTel,
                                            }}
                                        ></div>
                                    </StyledIconContactWrapper>
                                    <StyledIconContactWrapper>
                                        <MdEmail />
                                        <div
                                            className="contact-details"
                                            dangerouslySetInnerHTML={{
                                                __html: contactEmail,
                                            }}
                                        ></div>
                                    </StyledIconContactWrapper>
                                </StyledFlexRowWrapper>
                            )}
                        </StyledContactCard>
                    </StyledSectionContent>
                    <StyledSectionContent>
                        <StyledImage
                            image={image}
                            tag="div"
                            alt="Bilde av ansatt"
                            className={`contact ${slimImage ? "slim" : ""}`}
                        />
                    </StyledSectionContent>
                </StyledContactWrapper>
            </StyledSplitRow>
        </Fragment>
    )
}

export default ArticleContact
